<template>
  <template v-if="$slots.buttonActive && $slots.buttonInactive">
    <template v-if="isExpanded">
      <button
        ref="buttonOutsideClick"
        class="active cursor-pointer w-full"
        :aria-expanded="isExpanded"
        @click="isExpanded = !isExpanded"
      >
        <slot name="buttonActive" />
      </button>
    </template>
    <template v-if="!isExpanded">
      <button
        ref="buttonOutsideClick"
        class="inactive cursor-pointer w-full"
        :aria-expanded="isExpanded"
        @click="isExpanded = !isExpanded"
      >
        <slot name="buttonInactive" />
      </button>
    </template>
  </template>
  <template v-if="$slots.head">
    <button
      :class="[{'active' : isExpanded},{'inactive' : !isExpanded}, 'cursor-pointer w-full']"
      :aria-expanded="isExpanded"
      @click="isExpanded = !isExpanded"
    >
      <slot name="head" />
    </button>
  </template>
  <template v-if="$slots.collapse">
    <Collapse :when="isExpanded" class="v-collapse">
      <slot name="collapse" />
    </Collapse>
  </template>
  <template v-if="$slots.collapseAbsolute">
    <div ref="onOutsideClick" :class="['absolute', props.absolutePosition]">
      <Collapse :when="isExpanded" class="v-collapse">
        <slot name="collapseAbsolute" />
      </Collapse>
    </div>
  </template>
  <template v-if="$slots.bottom">
    <button
      :class="[{'active' : isExpanded}, {'inactive' : !isExpanded}, 'cursor-pointer w-full']"
      :aria-expanded="isExpanded"
      @click="isExpanded = !isExpanded"
    >
      <slot name="bottom" />
    </button>
  </template>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Collapse } from 'vue-collapsed'

const props = defineProps({
  outsideClick: {
    type: Boolean,
    default: false
  },
  absolutePosition: {
    type: String,
    default: ''
  }
})

const isExpanded = ref(false)

const onOutsideClick = ref()
const buttonOutsideClick = ref()
const listener = (event) => {
  if (props.outsideClick && !onOutsideClick.value?.contains(event.target) && !buttonOutsideClick.value?.contains(event.target)) {
    isExpanded.value = false
  }
}
onMounted(() => { window.addEventListener('mouseup', listener) })
onBeforeUnmount(() => { window.removeEventListener('mouseup', listener) })
</script>

<style scoped>
.v-collapse {
  @apply transition-[height] duration-[700ms] ease-in-out;
}
.v-collapse[data-collapse='expanded']{
  @apply opacity-100;
}

.v-collapse[data-collapse='collapsed']{
  @apply h-0 opacity-0;
}
</style>
